<template>
  <VueEasyLightbox
    :visible="index !== null"
    :imgs="items"
    :index="index"
    @hide="index = null"
  >
  </VueEasyLightbox>
  <div v-if="media" class="flex flex-wrap">
    <figure class="w-full relative" :class="[{ 'is-active': media.active }]">
      <img
        class="w-full cursor-pointer"
        :src="thumbnail"
        :alt="$t('questions.questions.questionImage')"
        @click="index = 0"
      />
    </figure>
  </div>
  <div v-else></div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useQuestionStore } from '@/store/modules/Question';
import Placeholder from '@/assets/images/video_placeholder.jpg';

const questionStore = useQuestionStore();

const index = ref(null);

const media = computed(() => questionStore.currentQuestion.activeMedia());
const items = computed(() => { 
  if (media.value) {
    return media.value.base64Tag
  }
  else {
    return ''
  }
});
const thumbnail = computed(() => {
  if (media.value.fileType === 'video') {
    return Placeholder;
  }
  return media.value.base64Tag;
});
</script>

<style lang="scss" scoped>
img {
  border-radius: 0.375rem;
  transform: scale(1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.is-active {
  position: relative;
  overflow: hidden;

  &::after {
    content: 'Aktiv';
    @apply bg-red-700 px-6 py-1 text-white uppercase;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &:hover img {
    transform: scale(1.05);
  }
}
</style>
